/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import styled from 'styled-components';
import theme from '../../styles/theme';


const FloatingMenuWrapper = styled.div`
  display: none;
  margin: 40px 0 0 0;

  &.to-bottom {
    display: flex;
    align-items: end;
  }

  .fixed {
    position: fixed;
    top: 100px; // This is because: HEADER_HEIGHT = 60; MARGIN_TOP = 40;
  }

  @media (min-width: 1200px) {
    display: initial;
    width: 180px;

    .fixed {
      width: 180px;
    }
    nav {
      width: 180px;
    }
  }

  @media (min-width: 1440px) {
    display: initial;
    width: 250px;

    .fixed {
      width: 250px;
    }
    nav {
      width: 250px;
    }
  }
  

  .items-wrapper {
    border-right: 1px solid ${theme.borderColor.silver};

    ul {
      margin: 0;
      padding: 0 20px 0 0;
      li {
        list-style-type: none;
        margin: 0 0 12px 0;
      }
    }
    .item {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: ${theme.textColor.lightBlack};
      display: block;

      &.main-item {
        font-weight: 700;
        margin: 0 0 24px 0;
      }
      &.selected,
      :hover {
        font-weight: 700;
      }
    }
  }
`;
FloatingMenuWrapper.displayName = 'FloatingMenuWrapper';

/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/
export default FloatingMenuWrapper;
