import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Icon from '../Icon';
import heroDots from './hero-dots.svg';
import redCircle from './red-circle.svg';
import { BodyText } from '../Global';
import theme from '../../styles/theme';

const Wrapper = styled.div`
  height: 460px;
  background: #1F2432;
  position: relative;

  .dot-bg {
    position: absolute;
    z-index: 2;
    top: 130px;
    left: 0px;
  }

  .red-circle {
    position: absolute;
    z-index: 2;
    top: 104px;
    right: 0;
  }

  .category-blue-circle {
    position: absolute;
    bottom: 30px;
    left: -30px;
  }

  .content-wrap {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;

    h1 {
      color: ${theme.heading.dark};
    }

    p,
    span {
      color: ${theme.heading.dark};
    }

    ${BodyText} {
      display: inline-block;
    }

    .blue-circle {
      position: absolute;
      z-index: 1;
      top: -20px;
      left: -20px;
    }

    .autocomplete-wrapper {
      .aa-Autocomplete {
        .aa-Form {
          box-shadow: none;
          padding: 6px 16px;
          outline: none;
          border-radius: 6px;
          border: 0;
          background: ${theme.body.dark};
          display: flex;
          min-width: 600px;
  
          .aa-InputWrapperPrefix {
            .aa-SubmitIcon {
              color: ${theme.body.light};
            }
          }
        }
        .aa-DetachedSearchButton {
          padding: 6px 16px;
          outline: none;
          border-radius: 6px;
          border: 0;
          background: ${theme.body.dark};
          display: flex;
          width: 80vw;

          .aa-DetachedSearchButtonIcon {
            color: ${theme.body.light};
          }
        }
      }
    }
    .ais-SearchBox {
      .ais-SearchBox-form {
        box-shadow: none;
        padding: 6px 16px;
        outline: none;
        border-radius: 6px;
        border: 0;
        background: ${theme.body.dark};
        display: flex;
        min-width: 600px;

        .ais-SearchBox-input {
          order: 2;
          appearance: none;
          background: none;
          border: 0;
          outline: none;
          height: 44px;
          padding: 0;
          width: 100%;

          &::placeholder {
            color: ${theme.textColor.suvaGray};
          }
        }
        .ais-SearchBox-submit {
          order: 1;
          background: none;
          border: none;
          display: flex;
          align-items: center;
          padding: 0 10px;

          .ais-SearchBox-submitIcon {
            height: 20px;
            width: 20px;
            fill: ${theme.body.light};
          }
        }
        .ais-SearchBox-reset {
          order: 3;
          cursor: pointer;
          background: none;
          border: none;
        }
      }
    }
    .gatsby-image-wrapper {
      width: 30px;
      height: 30px;
      margin-left: 3px;
      display: inline-block;
      vertical-align: bottom;
      margin-bottom: -5px;
    }
  }

  @media (max-width: 767px) {
    height: 315px;
    .dot-bg {
      top: 50px;
      left: -205px;
    }
    .red-circle {
      top: 0px;
      right: -50px;
    }
    .content-wrap {
      .blue-circle {
        display: none;
      }
    }
  }
  @media (min-width: 768px) and (max-width: 989px) {
    .dot-bg {
      top: 103px;
      left: -120px;
    }
    .red-circle {
      right: -50px;
    }
    p {
      width: 310px;
      margin: 12px auto 28px;
    }
  }
`;

const HelpHero = ({ children, showBlueCircle }) => (
  <Wrapper>
    <div className="dot-bg">
      <img src={heroDots} alt="search hero dots" />
    </div>
    <div className="red-circle">
      <img src={redCircle} alt="red-circle" />
    </div>
    <div className="content-wrap">
      {children}
    </div>
    {showBlueCircle ? (
      <div className="category-blue-circle">
        <Icon name="blueCircle" color={theme.icon.blueIcon} />
      </div>
    ) : (
      <></>
    )}
  </Wrapper>
);

HelpHero.defaultProps = {
  showBlueCircle: false,
};
HelpHero.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired,
  showBlueCircle: PropTypes.bool,
};

export default HelpHero;
