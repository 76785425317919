import styled from 'styled-components';
import theme from '../styles/theme';
import {
  ContainerChildrenWrapper,
} from '../components/Container';
import {
  BodyText,
} from '../components/Global';

const CategorySection = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px 0 0 0;

  ul li {
    list-style: none;
    margin: 0;

    a {
      font-size: ${theme.font.smallParagraph.fontSize};
      color: ${theme.textColor.blueText};
    }
  }

  ${BodyText} {
    color: ${theme.textColor.lightBlack};
    font-size: ${theme.font.smallParagraph.fontSize};
  }
  .folder-name {
    color: ${theme.textColor.lightBlack};
    margin: 0 0 10px 0;
  }
  

  @media(max-width: 990px) {
    max-width: 723px;
    width: 100%;
  }
  @media(max-width: 767px) {
    padding: 58px 37px;
  }
`;
CategorySection.displayName = 'CategorySection';

const ContainerChildrenWrapperForCategory = styled(ContainerChildrenWrapper)`
`;
ContainerChildrenWrapperForCategory.displayName = 'ContainerChildrenWrapperForCategory';

export {
  CategorySection,
  ContainerChildrenWrapperForCategory,
};
export default null;
