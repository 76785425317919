/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
// Helpers
import urlHelper from '../../utils/url';


const HelpFolderArticles = ({ articles }) => (
  <ul>
    {articles.map(article => (
      <li key={article.slug}>
        <Link to={urlHelper.getHelpArticle(article.slug)}>
          {article.title}
        </Link>
      </li>
    ))}
  </ul>
);

/*------------------------------------------------*/
/* PROP TYPES
/*------------------------------------------------*/
HelpFolderArticles.propTypes = {
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string,
      title: PropTypes.string,
    }).isRequired,
  ).isRequired,
};

/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/
export default HelpFolderArticles;
