/* eslint-disable react/no-danger */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import _get from 'lodash/get';
// eslint-disable-next-line import/no-cycle
import Layout from '../components/layout';
import Container from '../components/Container';
import SEO from '../components/seo';
import HelpHero from '../components/Help/HelpHero';
import {
  ContentWrap,
  HeroHeading,
  SmallTitle,
  BodyText,
} from '../components/Global';
import HelpFolderArticles from '../components/HelpFolderArticles';
import FloatingMenu from '../components/FloatingMenu';
// Helpers
import urlHelper from '../utils/url';
// Styles
import {
  CategorySection,
  ContainerChildrenWrapperForCategory,
} from './helpCategoryTemplate.style';

const getCategory = data => _get(data, 'contentfulHelpCategory', {});

const getArticles = (data) => {
  const articles = _get(data, 'allContentfulHelpArticle.edges', [])
    .map(article => ({ ...article.node }));

  return articles;
};

const getSubCategories = (data, articles) => {
  let subCategories = _get(data, 'allContentfulHelpSubcateogry.nodes', []);
  subCategories = subCategories
    .map(subCategory => ({
      ...subCategory,
      url: `#${urlHelper.convertToSlug(subCategory.name)}`,
      articles: articles
        .filter(article => (article.subCategory[0].id === subCategory.id)),
    }))
    .filter(subCategory => (subCategory.articles.length > 0));

  return subCategories;
};


const HelpCategoryTemplate = (props) => {
  const {
    data,
    location,
  } = props;
  const category = getCategory(data);
  const articles = getArticles(data);
  const subCategories = getSubCategories(data, articles);

  const seoDescription = _get(category, 'seoDescription.seoDescription', '');
  const categoryImage = _get(category, 'categoryImage.gatsbyImageData', '');
  const seoMeta = {
    title: category.seoTitle || `${category.categoryTitle} | Help Center | Workast`,
    description: seoDescription || _get(category, 'categoryDescription', ''),
  };

  return (
    <Layout
      location={location}
      useDarkBackground
    >
      <SEO
        title={seoMeta.title}
        description={seoMeta.description}
      />
      <HelpHero showBlueCircle>
        <HeroHeading>{_get(category, 'categoryTitle', '')}</HeroHeading>
        <BodyText>
          {_get(category, 'categoryDescription', '')}
          { categoryImage && <GatsbyImage alt={_get(category, 'categoryTitle', '')} image={getImage(categoryImage)} />}
        </BodyText>
      </HelpHero>
      <Container>
        <ContainerChildrenWrapperForCategory>
          <FloatingMenu
            mainItem={{
              name: 'Categories',
            }}
            items={subCategories}
            // selectedItemId={selectedSubCategoryId}
          />
          <ContentWrap>
            <CategorySection>
              {subCategories.map(subCategory => (
                <Fragment key={subCategory.id}>
                  <SmallTitle
                    id={urlHelper.convertToSlug(subCategory.name)}
                    className="folder-name"
                  >
                    {subCategory.name}
                  </SmallTitle>
                  {_get(subCategory, 'excerpt.excerpt', '') && (
                    <BodyText mb="18px">
                      {_get(subCategory, 'excerpt.excerpt', '')}
                    </BodyText>
                  )}
                  <HelpFolderArticles articles={subCategory.articles} />
                </Fragment>
              ))}
            </CategorySection>
          </ContentWrap>
        </ContainerChildrenWrapperForCategory>
      </Container>
    </Layout>
  );
};

HelpCategoryTemplate.propTypes = {
  data: PropTypes.shape({
    allContentfulHelpArticle: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            category: PropTypes.arrayOf(
              PropTypes.shape({
                category: PropTypes.shape({
                  id: PropTypes.string,
                }),
              })
            ),
            id: PropTypes.string,
            slug: PropTypes.string,
            title: PropTypes.string,
          }),
        })
      ),
    }).isRequired,
    allContentfulHelpSubcateogry: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
        })
      ),
    }),
    contentfulHelpCategory: PropTypes.shape({
      categoryDescription: PropTypes.string,
      categoryTitle: PropTypes.string,
      id: PropTypes.string,
      seoDescription: PropTypes.shape({
        seoDescription: PropTypes.string,
      }),
      seoTitle: PropTypes.string,
      slug: PropTypes.string,
    }).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    host: PropTypes.string,
    hostname: PropTypes.string,
    href: PropTypes.string,
    key: PropTypes.string,
    origin: PropTypes.string,
    pathname: PropTypes.string,
    port: PropTypes.string,
  }).isRequired,
};

export default HelpCategoryTemplate;

export const pageQuery = graphql`
  query ContentfulHelpCategory ($categoryId: String!) {
    contentfulHelpCategory(id: {eq: $categoryId}) {
      id
      slug
      categoryTitle
      categoryDescription
      seoTitle
      seoDescription {
        seoDescription
      }
      categoryImage {
        gatsbyImageData(width: 80, height: 80)
      }
    }

    allContentfulHelpSubcateogry(
      filter: {category: {id: {eq: $categoryId}}}
      sort: {fields: order, order: ASC}
    ) {
      nodes {
        id
        name
        excerpt {
          excerpt
        }
      }
    }

    allContentfulHelpArticle(
      filter: {category: {elemMatch: {category: {id: {eq: $categoryId}}}}},
      sort: {fields: [sort, title], order: ASC}
    ) {
      edges {
        node {
          id
          slug
          title
          subCategory:category {
            id
          }
        }
      }
    }
  }
`;
