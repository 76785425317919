/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import React, {
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
import FloatingMenuWrapper from './index.style';
import { breakpointup } from '../../styles/MediaBreakUp';

const LIST_WRAPPER_ID = 'list-wrapper-id';
const HEADER_HEIGHT = 60;
const MARGIN_TOP = 40;
const TOTAL_HEIGHT = (HEADER_HEIGHT + MARGIN_TOP);

/*------------------------------------------------*/
/* COMPONENT
/*------------------------------------------------*/
const FloatingMenu = (props) => {
  // Calculate sidebar position:
  useEffect(() => {
    // Get the navbar
    const navbar = document.getElementById(LIST_WRAPPER_ID);
    // Get the offset position of the navbar
    let stickyOffsetTop = navbar.querySelector('.items-wrapper').offsetTop - TOTAL_HEIGHT;

    // Add the "fixed" class to the navbar when you reach its scroll position.
    // Remove "fixed" when you leave the scroll position
    function checkCSSClassForSidebar() {
      const itemsWrapperElement = navbar.querySelector('.items-wrapper').getBoundingClientRect();
      const parentElement = navbar.parentElement.parentElement.getBoundingClientRect();
      const shouldBeVisible = window.innerWidth > breakpointup.xl;

      // <ContainerChildrenWrapper>: This is "navbar.parentElement.parentElement".
      const shouldBeAtTheBottom = (
        (itemsWrapperElement.height) > (parentElement.height + parentElement.top - TOTAL_HEIGHT)
      );
      const shouldBeFixed = (window.scrollY >= stickyOffsetTop);

      if (shouldBeAtTheBottom && shouldBeVisible) {
        navbar.classList.remove('fixed');
        navbar.parentElement.classList.add('to-bottom');
      }
      else if (shouldBeFixed && shouldBeVisible) {
        navbar.classList.add('fixed');
        navbar.parentElement.classList.remove('to-bottom');
      }
      else {
        navbar.classList.remove('fixed');
        navbar.parentElement.classList.remove('to-bottom');
      }
    }

    const updateTotalSize = () => {
      stickyOffsetTop = navbar.querySelector('.items-wrapper').offsetTop + window.scrollY - TOTAL_HEIGHT;
    };

    window.addEventListener('scroll', checkCSSClassForSidebar);
    window.addEventListener('resize', updateTotalSize);

    // Execute the function just for the first time:
    checkCSSClassForSidebar();

    const cleanUp = () => {
      window.removeEventListener('scroll', checkCSSClassForSidebar);
      window.removeEventListener('resize', updateTotalSize);
    };

    return cleanUp;
  }, []);

  const {
    mainItem,
    items,
    selectedItemId,
  } = props;

  const mainItemName = _get(mainItem, 'name', '');
  const mainItemUrl = _get(mainItem, 'url', '');

  return (
    <FloatingMenuWrapper>
      <nav id={LIST_WRAPPER_ID}>
        <div className="items-wrapper">
          {mainItemUrl && (
            <a
              className="item main-item"
              href={mainItemUrl}
            >
              {mainItemName}
            </a>
          )}
          {!mainItemUrl && mainItemName && (
            <span
              className="item main-item"
            >
              {mainItemName}
            </span>
          )}
          <ul>
            {items.map(item => (
              <li key={item.id}>
                <a
                  href={_get(item, 'url', '')}
                  className={item.id === selectedItemId ? 'selected item' : 'item'}
                >
                  {`${item.name}${item.id === selectedItemId ? ' >' : ''}`}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </nav>
    </FloatingMenuWrapper>
  );
};

/*------------------------------------------------*/
/* PROP TYPES
/*------------------------------------------------*/
FloatingMenu.defaultProps = {
  mainItem: null,
  items: [],
  selectedItemId: null,
};
FloatingMenu.propTypes = {
  mainItem: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    url: PropTypes.string,
  }),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
  selectedItemId: PropTypes.string,
};

/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/
export default FloatingMenu;
